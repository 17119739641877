import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const CommercialManagement = () => {
    return (
        <Layout>
            <Seo title="Commercial Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Commercial Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        We handle chartering, marine operations and marine Control matters to ensure service
                                        reliability for our customers. Our team works closely with owners and charterers to develop
                                        tailor made solutions and strategies for maximum vessel employment success and smooth ship
                                        operations.
                                    </p>
                                    <p>
                                        An extensive charter relationship management with personal contacts ensures a large network
                                        amongst all major charterers and makes sure most attractive employment possibilities.
                                    </p>
                                    <p>
                                        More details about commecial management can be found on the Delta Corp Bulk Logistics{" "}
                                        <Link to="/bulk-logistics/">website</Link>.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage alt="Sales team" src="../../../images/other/port3.jpg" aspectRatio={16 / 9} width={696} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CommercialManagement
